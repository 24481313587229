import React from "react"
import { Link } from "gatsby"

export default class CoursesAndBlogs extends React.Component {
  render() {
    return (
      <div className="grid gap-8 xl:gap-10 sm:grid-cols-2 lg:grid-cols-4 grid-cols-1">
        <div className="pt-4 xl:pt-6 pb-6 xl:pb-8 text-base xl:text-lg 2xl:text-xl shadow-md border rounded-2xl">
          <div className="flex justify-center">
            <h2 className="tracking-wider text-gray-900 ">
              <span className="">Coding Interview</span>
            </h2>
          </div>

          <div className="grid justify-center text-center">
            <div className="mt-5 xl:mt-6">
              <Link to="/data-structures-and-algorithms-course/">
                <div className="z-30 tracking-wider border border-new-purple shadow bg-white text-new-purple hover:bg-new-purple hover:text-white px-4 py-2 rounded transition duration-150">
                  DSA Course
                </div>
              </Link>
            </div>

            <div className="mt-5 xl:mt-6">
              <Link to="/coding-interview/">
                <div className="z-30 tracking-wider border border-new-green shadow bg-white text-new-green hover:bg-new-green hover:text-white px-4 py-2 rounded transition duration-150">
                  DSA Blogs
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="pt-4 xl:pt-6 pb-6 xl:pb-8 text-base xl:text-lg 2xl:text-xl rounded-2xl border shadow-md">
          <div className="flex justify-center">
            <h2 className="tracking-wider  text-gray-900">
              <span className="">Machine Learning</span>
            </h2>
          </div>

          <div className="grid justify-center tracking-wider  text-center">
            <div className="mt-5 xl:mt-6">
              <Link to="/machine-learning-courses/">
                <div className="z-30 tracking-wider border border-new-purple shadow bg-white text-new-purple hover:bg-new-purple hover:text-white px-4 py-2 rounded transition duration-150">
                  ML Course
                </div>
              </Link>
            </div>
            <div className="mt-5 xl:mt-6">
              <Link to="/machine-learning/">
                <div className="z-30 tracking-wider border border-new-green shadow bg-white text-new-green hover:bg-new-green hover:text-white px-4 py-2 rounded transition duration-150">
                  ML Blogs
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="pt-4 xl:pt-6 pb-6 xl:pb-8 text-base xl:text-lg 2xl:text-xl rounded-2xl border shadow-md">
          <div className="flex justify-center">
            <h2 className="tracking-wider text-gray-900 ">
              <span className="">System Design</span>
            </h2>
          </div>

          <div className="grid justify-center tracking-wider text-center">
            <div className="mt-5 xl:mt-6">
              <Link to="/system-design-courses/">
                <div className="z-30 tracking-wider border border-new-purple shadow bg-white text-new-purple hover:bg-new-purple hover:text-white px-4 py-2 rounded transition duration-150">
                  SD Course
                </div>
              </Link>
            </div>
            <div className="mt-5 xl:mt-6">
              <Link to="/system-design/">
                <div className="z-30 tracking-wider border border-new-green shadow bg-white text-new-green hover:bg-new-green hover:text-white px-4 py-2 rounded transition duration-150">
                  SD Blogs
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="pt-4 xl:pt-6 pb-6 xl:pb-8 rounded-2xl text-base xl:text-lg 2xl:text-xl border shadow-md">
          <div className="flex justify-center">
            <h2 className="tracking-wider  text-gray-900">
              <span className="">OOP Concepts</span>
            </h2>
          </div>

          <div className="grid justify-center tracking-wider text-center">
            <div className="mt-5 xl:mt-6">
              <Link to="/oops-course/">
                <div className="z-30 tracking-wider shadow border border-new-purple bg-white text-new-purple hover:bg-new-purple hover:text-white px-4 py-2 rounded transition duration-150">
                  OOP Course
                </div>
              </Link>
            </div>
            <div className="mt-5 xl:mt-6">
              <Link to="/oops-concepts/">
                <div className="z-30 tracking-wider border border-new-green shadow bg-white text-new-green hover:bg-new-green hover:text-white px-4 py-2 rounded transition duration-150">
                  OOP Blogs
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
