import React from "react"
import Interview from "../img/interview.svg"
import Industry from "../img/code.svg"
import Interest from "../img/creative.svg"

export default class Team extends React.Component {
  render() {
    return (
      <div className="mt-4">
        <div className="rounded">
          <div className="grid text-gray-600 justify-items-stretch grid-cols-3 gap-14">
            <div className="grid gap-3 justify-items-center">
              <img
                className="w-12 h-12 lg:w-16 lg:h-16"
                src={Industry}
                alt="Software industry"
              />
              <p className="tracking-wider text-center text-sm lg:text-base 2xl:text-lg">
                Industry
              </p>
            </div>

            <div className="grid gap-3 justify-items-center">
              <img
                className="w-12 h-12 lg:w-16 lg:h-16"
                src={Interview}
                alt="Tech interview"
              />
              <p className="tracking-wider text-center text-sm lg:text-base 2xl:text-lg">
                Interview
              </p>
            </div>

            <div className="grid gap-3 justify-items-center">
              <img
                className="w-12 h-12 lg:w-16 lg:h-16"
                src={Interest}
                alt="Interest"
              />
              <p className="tracking-wider text-center text-sm lg:text-base 2xl:text-lg">
                Interest
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
