import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Helmet } from "react-helmet"
import NavigationBar from "../components/Navbar"
import CoursesAndBlogs from "../components/CoursesAndBlogs"
import CompanyStrip from "../components/NewCompanyStrip"
import SocialMedia from "../components/social-media"
import homeImg from "../img/home_20.svg"
import LiveCourses from "../components/LiveCourseComponent"
import blogImg from "../img/blog-page1.svg"
import SitePreview from "../img/site-preview.png"
import loadable from "@loadable/component"

const BlogRoll = loadable(() => import("../components/BlogRoll"))
const Footer = loadable(() => import("../components/footer3"))
const Scroll = loadable(() => import("../components/scroll"))

const Home = () => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleInputChange = event => {
    const { value } = event.target
    setEmail(value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(email)
    setMessage(result.msg)
  }

  return (
    <div className="">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>EnjoyAlgorithms</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          property="og:description"
          content="EnjoyAlgorithms create industry, interview, and interest-focused content with guided structure and help learners to achieve a dream career in computer science."
        />
        <meta name="image" property="og:image" content={SitePreview} />
        <meta
          name="keywords"
          content="dsa, algorithms, data structures, machine learning, computer science, coding interview course, dsa course, dsa online course, dsa concepts, coding interview questions, learn data structures and algorithms, data structures and algorithms course, machine course, ml course, ml online course, learn machine learning, machine learning course, system design interview, system design course, system design questions, system design concepts, oops concepts, object oriented programming, oops course, oops interview."
        />
        <meta name="title" property="og:title" content="EnjoyAlgorithms" />
      </Helmet>

      <header className="sm:sticky top-0 z-50">
        <NavigationBar />
      </header>

      <main className="bg-light-white">
        <div className="container mx-auto overflow-hidden">
          <div className="pt-5 md:pt-6 xl:pt-7 2xl:pt-10 px-6 xl:px-8 2xl:px-10">
            <div className="md:grid md:grid-cols-12">
              <div className="col-span-7 sm:text-center md:text-left">
                <h1 className="tracking-wider text-gray-900 text-2xl xl:text-3xl 2xl:text-4xl">
                  <span className="">Enjoy Computer Science</span>
                </h1>
                <div className="mt-4 2xl:mt-5 mx-auto md:mx-0 sm:max-w-lg md:max-w-sm lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl">
                  <p className="mt-4 text-gray-600 tracking-wider text-base xl:text-lg 2xl:text-xl text-left sm:text-center md:text-left">
                    Subscribe to get well-designed content on data structures
                    and algorithms, machine learning, system design, and OOP
                    design.
                  </p>

                  <div
                    className="my-2 prose-lg text-new-green"
                    dangerouslySetInnerHTML={{
                      __html: message,
                    }}
                  />
                  <form
                    name="subscribeForm"
                    onSubmit={handleSubmit}
                    id="subscribe-form"
                    method="POST"
                    className="mt-3 sm:mt-6 xl:mt-8 2xl:mt-10 sm:flex"
                  >
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                      className="px-4 py-3 xl:py-4 w-full tracking-wider rounded text-xs xl:text-sm bg-light-white 2xl:text-sm border-b border-new-purple placeholder-gray-600 focus:outline-none focus:border-gray-300"
                      placeholder="Email"
                      onFocus={e => (e.target.placeholder = "")}
                      onBlur={e => (e.target.placeholder = "Email")}
                      aria-label="Email Address"
                    />

                    <button
                      type="submit"
                      id="subscribeButton"
                      className="w-full sm:w-auto bg-white px-5 py-3 xl:py-4 mt-3 sm:mt-0 border border-new-purple rounded text-base xl:text-lg 2xl:text-xl text-new-purple hover:text-white hover:bg-new-purple shadow transition duration-150 focus:outline-none focus:ring focus:ring-purple-300"
                      aria-label="Subscribe Button"
                    >
                      <div className="justify-center flex">
                        <svg
                          className="flex-shrink-0 w-6 h-6 xl:w-7 xl:h-7"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                        <p className="ml-3  tracking-wider "> Subscribe</p>
                      </div>
                    </button>
                  </form>

                  <div className="mt-8 xl:mt-12 2xl:mt-16">
                    <CompanyStrip />
                  </div>

                  <div className="mt-6 flex justify-center md:hidden">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "79.47%",
                      }}
                    >
                      <img
                        className="object-cover pointer-events-none absolute top-0"
                        src={homeImg}
                        alt="Learn in-demand tech skills with enjoy algorithms"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:-mt-4 xl:-mt-5 2xl:-mt-6 col-span-5 hidden md:flex">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "79.47%",
                  }}
                >
                  <img
                    className="pointer-events-none object-cover ml-4 sm:ml-6 xl:ml-8 2xl:ml-10 absolute top-0"
                    src={homeImg}
                    alt="Learn computer computer science with enjoy algorithms"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 xl:mt-3 2xl:mt-2">
              <CoursesAndBlogs />
            </div>
            <LiveCourses />

            <div className="mt-10 lg:mt-8 md:grid md:grid-cols-12">
              <div className="max-w-auto mx-0 col-span-7">
                <h2 className="mt-6 lg:mt-8 xl:mt-12 2xl:mt-16 text-gray-900 text-2xl xl:text-3xl 2xl:text-4xl tracking-wider text-left sm:text-center md:text-left ">
                  <span className="">Recently Published/Updated</span>
                </h2>

                <p className="mt-4 tracking-wider text-left sm:text-center md:text-left mx-auto md:mx-0 sm:max-w-lg md:max-w-sm lg:max-w-lg xl:max-w-xl text-base xl:text-lg 2xl:text-xl text-gray-600">
                  We design interview and industry-centric content with in-depth
                  explanations and help learners develop a dedicated interest in
                  computer science, coding, mathematics, and problem-solving.
                  Enjoy learning, enjoy thinking.
                </p>
              </div>

              <div className="mt-6 lg:mt-4 xl:mt-2 2xl:mt-0 col-span-5 flex justify-center">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "60%",
                  }}
                >
                  <img
                    className="object-cover pointer-events-none ml-0 sm:ml-2 md:ml-4 lg:ml-6 xl:ml-8 absolute top-0"
                    src={blogImg}
                    alt="Latest content on enjoyalgorithms"
                  />
                </div>
              </div>
            </div>
            <section>
              <div className="mt-4 xl:mt-2">
                <BlogRoll />
              </div>
            </section>
            <SocialMedia />
            <Scroll showBelow={250} />
            <Footer />
          </div>
        </div>
      </main>
    </div>
  )
}

export default Home
